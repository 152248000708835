export const MIN_SALARY = 1;
export const MAX_SALARY = 999999;
export const AGE_LIMIT = {
  MIN: 23,
  MAX: 65,
};
export const ELIGIBLE_PARTNERS = [
  'RBL',
  'DMI',
  'INCRED',
  'FULLERTON',
  'HDBFS',
  'CREDITSAISON',
  'LENDBOX',
];
export const ELIGIBLE_LINE_TYPES = ['CASH_LINE'];
export const LINE_TYPES = {
  CF: 'CONSUMER_FINANCE',
  CL: 'CASH_LINE',
  FC: 'FAST_CASH',
};
export const SALARY_LIMITS = {
  MIN: 25000,
};
export const AIP_LINE_ELIGIBLE_PARTNERS = [
  'DMI',
  'RBL',
  'INCRED',
  'FULLERTON',
  'TAPSTART',
  'CREDITSAISON',
  'HDBFS',
  'MUSKMELON',
  'LENDBOX',
  'CHOLAMANDALAM',
];

export const IMPS_ELIGILBLE_PARTNERS = [
  'TAPSTART',
  'CHOLAMANDALAM',
  'CREDITSAISON',
  'INCRED',
  'LENDBOX',
];

export const IMPS_ELIGIBLE_LINE_TYPES = ['CASH_LINE', 'FAST_CASH'];

export const REPAYMENT_ELIGIBLE_PARTNERS = [
  'INCRED',
  'TAPSTART',
  'CREDITSAISON',
  'DMI',
  'MUSKMELON',
  'FULLERTON',
  'HDBFS',
];

export const CONFIN_ELIGIBLE_PARTNERS = ['DMI'];

export const HIDE_LSF_PARTNERS = ['CREDITSAISON', 'HDBFS'];

export const SHOW_CM_LAF_TNC_PARTNERS = [
  'CHOLAMANDALAM',
  'MUSKMELON',
  'HDBFS',
  'INCRED',
];

export const BENEFICIARY_BANK_LIST: Record<string, string> = {
  RBL: 'ALL',
  DMI: 'ALL',
  INCRED: 'ALL',
  FULLERTON: 'ALL',
  CREDITSAISON: 'ALL',
  HDBFS: 'ALL',
  MUSKMELON: 'EMANDATE',
  DEFAULT: 'ALL',
};

export const KFS_ELIGIBLE_PARTNERS = [
  'DMI',
  'INCRED',
  'FULLERTON',
  'HDBFS',
  'CREDITSAISON',
  'MUSKMELON',
  'LENDBOX',
  'CHOLAMANDALAM',
];
export const HOST_TO_UTM_SOURCE_MAPPING: Record<string, string> = {
  'v1.freo.money': 'mktg_sms_freo',
  'v1.moneytap.com': 'mktg_sms_mnytp',
  'v2.freo.money': 'mktg_fb_freo',
  'v2.moneytap.com': 'mktg_fb_mnytp',
  'v3.freo.money': 'mktg_gg_freo',
  'v3.moneytap.com': 'mktg_gg_mnytp',
  'v4.freo.money': 'mktg_othr_freo',
  'v4.moneytap.com': 'mktg_othr_mnytp',
  'v5.freo.money': 'mktg_mail_freo',
  'v5.moneytap.com': 'mktg_mail_mnytp',
  'v6.freo.money': 'mktg_rcs_freo',
  'v6.moneytap.com': 'mktg_rcs_mnytp',
  'v7.freo.money': 'mktg_wa_freo',
  'v7.moneytap.com': 'mktg_wa_mnytp',
  // 'qa-web.moneytap.com': 'qa-webapp-testing',
  'v8.freo.money': 'mktg_rbl_freo',
  'v8.moneytap.com': 'mktg_rbl_mnytp',
};
